import React from "react"
import { Descriptions } from "antd"
import "@style/appraisee_info.less"
import { transformDate } from "@action"

const AppraiseeDescription = React.memo(
  ({ appraisal, attendanceRecord, cycle }) => {
    return (
      <Descriptions
        layout="vertical"
        column={3}
        className="person-descriptions"
      >
        <Descriptions.Item label="Full Name">
          {appraisal.name}
        </Descriptions.Item>

        <Descriptions.Item label="Staff Number">
          {appraisal.staffNo}
        </Descriptions.Item>

        <Descriptions.Item label="Company">
          {appraisal.company}
        </Descriptions.Item>
        <Descriptions.Item label="Site / Department">
          {appraisal.siteOrDepartment}
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          {appraisal.position}
        </Descriptions.Item>

        <Descriptions.Item label="Date Joined">
          {transformDate(appraisal.dateJoined)}
        </Descriptions.Item>

        {attendanceRecord && (
          <>
            <Descriptions.Item label="Lateness Times">
              {attendanceRecord.latenessTimes + ""}
            </Descriptions.Item>
            <Descriptions.Item label="Lateness Minutes">
              {attendanceRecord.latenessMinutes + ""}
            </Descriptions.Item>
            <Descriptions.Item label="Failing to clock in / out">
              {attendanceRecord.failingToClock + ""}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="Years In Current Position">
          {appraisal.yearInCurrentPosition ||
          appraisal.yearInCurrentPosition === 0
            ? appraisal.yearInCurrentPosition + ""
            : "N/A"}
        </Descriptions.Item>

        <Descriptions.Item label="Review Period" span={2}>
          {transformDate(cycle.appraisalStartDate) +
            " to " +
            transformDate(cycle.appraisalEndDate)}
        </Descriptions.Item>
      </Descriptions>
    )
  }
)

export default AppraiseeDescription
