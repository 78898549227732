import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { Row, Spin, Col } from "antd"
import { navigate } from "gatsby"
import {
  AppraisalClient,
  CoreCompetencyDefinitionSetClient,
  AppraisalTemplateClient,
} from "@api"
import "@style/CycleTableTemplate.less"
import { PdfView, AppraiseeDescription } from "@components/FrontLine"
import { getScoreByStep } from "@action"

const FrontlineDetailPage = ({ id }) => {
  const [data, setData] = useState({
    appraisal: null,
    template: {
      ratingList: {
        core: [],
        score: [],
      },
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async appraisalId => {
      try {
        setIsLoading(true)
        const client = new AppraisalClient()
        const templateClient = new AppraisalTemplateClient()
        const appraisalContent = await client.get2(appraisalId)
        const score = await client.getScore(appraisalId)
        const templateResult = await templateClient.get2(
          appraisalContent.content.appraisal.appraisalTemplateId
        )
        const coreClient = new CoreCompetencyDefinitionSetClient()
        const coreResult = await coreClient.get2(
          templateResult.coreCompetencyDefinitionSetId
        )

        const currentStep = appraisalContent.content.currentStep
        const coreCompetencyList = coreResult.coreCompetencyDefinitions.map(
          core => {
            return {
              item: { ...core, minScore: 0, maxScore: core.score },
              history: getScoreByStep(
                appraisalContent.content.appraisal.appraisalCoreCompetency,
                "coreCompetencyDefinitionId",
                core.coreCompetencyDefinitionId,
                currentStep?.step
              ),
              isCore: true,
            }
          }
        )

        const scoreAdjustmentList = coreResult.scoreAdjustmentDefinitions.map(
          score => {
            return {
              item: score,
              history: getScoreByStep(
                appraisalContent.content.appraisal.appraisalScoreAdjustment,
                "scoreAdjustmentDefinitionId",
                score.scoreAdjustmentDefinitionId,
                currentStep?.step
              ),
              isCore: false,
            }
          }
        )

        setData({
          appraisalId: appraisalContent.content.appraisal.id,
          appraisal: appraisalContent.content,
          score: score,
          hasEdit: appraisalContent.content.hasEdit,
          isHaveReturn: appraisalContent.content.currentStep?.isHaveReturn,
          template: {
            ...templateResult,
            ratingList: {
              core: coreCompetencyList,
              score: scoreAdjustmentList,
            },
          },
          currentStep: currentStep?.step,
        })
        setIsLoading(false)
      } catch (err) {
        navigate("/")
        console.log(err)
      }
    }

    fetchData(id)
  }, [id])

  return (
    <MainLayout>
      <Seo title="Frontline Search" />
      <Spin spinning={isLoading} size="large">
        <Row gutter={[12, 12]} className="report_table" justify="center">
          <Col span={24}>
            {data.appraisal && (
              <AppraiseeDescription
                appraisal={data.appraisal.appraisal}
                attendanceRecord={data.appraisal.attendanceRecord}
                cycle={data.appraisal.appraisal.appraisalCycle}
              />
            )}
          </Col>
          <Col span={24}>
            <PdfView
              data={data}
              degree360Weighting={data.template.degree360Weighting}
              coreCompetencyWeighting={data.template.coreCompetencyWeighting}
              siteKPIWeighting={data.template.siteKPIWeighting}
            />
          </Col>
        </Row>
      </Spin>
    </MainLayout>
  )
}

export default FrontlineDetailPage
